<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Shifts</p>
      <div v-if="$store.state.partner != ''" class="" style="font-size: 13px">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-if="$store.state.day_block > 0">
          <div v-if="$store.state.whichToShow == 'keno'">
            <div
              v-if="!pageLoading"
              class="white pa-10 pl-1 pr-1"
              id="report"
              style="border-radius: 3px"
            >
              <v-layout row wrap justify-space-around="">
                <v-flex
                  xs12
                  class="text-center"
                  v-if="paidKenoMoneyFromYesterday.length > 0"
                >
                  <h3 class="red--text">Today paid from yesterday</h3>
                  <span
                    v-for="(paidFromYesterday, i) in paidKenoMoneyFromYesterday"
                    :key="i"
                  >
                    <strong>{{ paidFromYesterday.cashierName }}:</strong>
                    {{ paidFromYesterday.paidMoney }} Birr
                    <br />
                  </span>
                </v-flex>
                <v-flex xs12 md5>
                  <div id="chooseDate" class="ml-10 mr-10 pl-10">
                    <h4 class="mt-6 grey--text text--darken-1 ml-10">
                      Select Keno Report Date
                    </h4>
                    <!-- <v-select
                    :items="items"
                    v-model="choosenDate"
                    label="Choose date"
                    dense
                    outlined
                  ></v-select> -->

                    <v-menu rounded="lg" offset-y>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          text
                          class="pl-6 pr-6 mt-2"
                          v-bind="attrs"
                          v-on="on"
                          outlined=""
                        >
                          {{ choosedDateRange }}
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item-group
                          v-model="group"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <v-list-item
                            v-for="item in items"
                            :key="item"
                            @click="
                              if (item == 'Custom Range') {
                                dateDialog = true;
                              } else {
                                getDailyWorks(item);
                              }
                            "
                          >
                            <v-list-item-title
                              v-text="item"
                            ></v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                class="mb-15 pa-3 pt-0"
                style="font-size: 14px"
              >
                <horizontal-scroll
                  class="horizontal-scroll white pa-3 pl-0 pt-0"
                  id="report"
                >
                  <table
                    style="width: 100%"
                    class="ma-3 mt-2"
                    v-if="
                      choosenDate == 'Today' ||
                      choosenDate == 'Yesterday' ||
                      choosenDate == 'Last 15 days'
                    "
                  >
                    <tr>
                      <th>Day</th>
                      <th>Full Name</th>
                      <th>No Tickets</th>
                      <th>No Paid Tickets</th>
                      <th>No Cancelled Tickets</th>
                      <th>No Bets</th>
                      <th>Total Money</th>
                      <th>Paid Money</th>
                      <th>On Hand</th>
                    </tr>

                    <tr v-for="(shift, i) in dailyReport" :key="i">
                      <td style="font-size: 11px">
                        <v-icon small>date_range</v-icon>
                        {{ weekday[shift.day] }}
                        <br v-if="shift.date != ''" />
                        <span class="ml-4">{{ shift.date }}</span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + dailyReport.length"
                        >
                          {{ cashier.fullName }}
                          <br />
                        </span>
                      </td>

                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + dailyReport.length + 1001"
                        >
                          {{ cashier.noTicket }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + dailyReport.length + 10000"
                        >
                          {{ cashier.noPaidTicket }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + dailyReport.length + 100000"
                        >
                          {{ cashier.nocancelledTicket }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + dailyReport.length + 1000000"
                        >
                          {{ cashier.noBet }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + dailyReport.length + 100"
                        >
                          {{ cashier.totalMoney }}
                          <span style="font-size: 11px">ETB</span>
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + dailyReport.length + 200"
                        >
                          {{ cashier.paidMoney }}
                          <span style="font-size: 11px">ETB</span>
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + dailyReport.length + 300"
                        >
                          {{ cashier.onHandMoney }}
                          <span style="font-size: 11px">ETB</span>
                          <br />
                        </span>
                      </td>
                    </tr>
                  </table>

                  <table style="width: 100%" class="ma-3 mt-2" v-else>
                    <tr>
                      <th>Day</th>
                      <th>Full Name</th>
                      <th>No Tickets</th>
                      <th>No Paid Tickets</th>
                      <th>No Cancelled Tickets</th>
                      <th>No Bets</th>
                      <th>Total Money</th>
                      <th>Paid Money</th>
                      <th>On Hand</th>
                    </tr>

                    <tr v-for="(shift, i) in dailyReport" :key="i">
                      <td style="font-size: 11px">
                        <v-icon small>date_range</v-icon>
                      </td>
                      <td>
                        <span>
                          {{ shift.fullName }}
                        </span>
                      </td>

                      <td>
                        <span>
                          {{ shift.noTicket }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.noPaidTicket }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.nocancelledTicket }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.noBet }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.totalMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.paidMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.onHandMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </horizontal-scroll>
              </v-layout>
            </div>
          </div>

          <div v-if="$store.state.whichToShow == 'spin'">
            <div
              v-if="!pageLoading"
              class="white pa-10 pl-1 pr-1"
              id="report"
              style="border-radius: 3px"
            >
              <v-layout row wrap justify-space-around="">
                <v-flex
                  xs12
                  class="text-center"
                  v-if="paidSpinMoneyFromYesterday.length > 0"
                >
                  <h3 class="red--text">Today paid from yesterday</h3>
                  <span
                    v-for="(paidFromYesterday, i) in paidSpinMoneyFromYesterday"
                    :key="i"
                  >
                    <strong> {{ paidFromYesterday.cashierName }}:</strong>
                    {{ paidFromYesterday.paidMoney }} Birr <br />
                  </span>
                </v-flex>
                <v-flex xs12 md5>
                  <div id="chooseDate" class="ml-10 mr-10 pl-10">
                    <h4 class="mt-6 grey--text text--darken-1 ml-10">
                      Select Spin Report Date
                    </h4>

                    <v-menu rounded="lg" offset-y>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          text
                          class="pl-6 pr-6 mt-2"
                          v-bind="attrs"
                          v-on="on"
                          outlined=""
                        >
                          {{ spinChoosedDateRange }}
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item-group
                          v-model="spinGroup"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <v-list-item
                            v-for="item in items"
                            :key="item"
                            @click="
                              if (item == 'Custom Range') {
                                dateDialog = true;
                              } else {
                                spinGetDailyWorks(item);
                              }
                            "
                          >
                            <v-list-item-title
                              v-text="item"
                            ></v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                class="mb-15 pa-3 pt-0"
                style="font-size: 14px"
              >
                <horizontal-scroll
                  class="horizontal-scroll white pa-3 pl-0 pt-0"
                  id="report"
                >
                  <table
                    style="width: 100%"
                    class="ma-3 mt-2"
                    v-if="
                      spinChoosenDate == 'Today' ||
                      spinChoosenDate == 'Yesterday' ||
                      spinChoosenDate == 'Last 15 days'
                    "
                  >
                    <tr>
                      <th>Day</th>
                      <th>Full Name</th>
                      <th>No Tickets</th>
                      <th>No Paid Tickets</th>
                      <th>No Cancelled Tickets</th>
                      <th>No Bets</th>
                      <th>Total Money</th>
                      <th>Paid Money</th>
                      <th>On Hand</th>
                    </tr>

                    <tr v-for="(shift, i) in spinDailyReport" :key="i">
                      <td style="font-size: 11px">
                        <v-icon small>date_range</v-icon>
                        {{ weekday[shift.day] }}
                        <br v-if="shift.date != ''" />
                        <span class="ml-4">{{ shift.date }}</span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length"
                        >
                          {{ cashier.fullName }}
                          <br />
                        </span>
                      </td>

                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 1001"
                        >
                          {{ cashier.noTicket }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 10000"
                        >
                          {{ cashier.noPaidTicket }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 100000"
                        >
                          {{ cashier.nocancelledTicket }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 1000000"
                        >
                          {{ cashier.noBet }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 100"
                        >
                          {{ cashier.totalMoney }}
                          <span style="font-size: 11px">ETB</span>
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 200"
                        >
                          {{ cashier.paidMoney }}
                          <span style="font-size: 11px">ETB</span>
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 300"
                        >
                          {{ cashier.onHandMoney }}
                          <span style="font-size: 11px">ETB</span>
                          <br />
                        </span>
                      </td>
                    </tr>
                  </table>

                  <table style="width: 100%" class="ma-3 mt-2" v-else>
                    <tr>
                      <th>Day</th>
                      <th>Full Name</th>
                      <th>No Tickets</th>
                      <th>No Paid Tickets</th>
                      <th>No Cancelled Tickets</th>
                      <th>No Bets</th>
                      <th>Total Money</th>
                      <th>Paid Money</th>
                      <th>On Hand</th>
                    </tr>

                    <tr v-for="(shift, i) in spinDailyReport" :key="i">
                      <td style="font-size: 11px">
                        <v-icon small>date_range</v-icon>
                      </td>
                      <td>
                        <span>
                          {{ shift.fullName }}
                        </span>
                      </td>

                      <td>
                        <span>
                          {{ shift.noTicket }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.noPaidTicket }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.nocancelledTicket }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.noBet }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.totalMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.paidMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.onHandMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </horizontal-scroll>
              </v-layout>
            </div>
          </div>

          <div v-if="$store.state.whichToShow == 'All'">
            <div
              v-if="!pageLoading"
              class="white pa-10 pl-1 pr-1"
              id="report"
              style="border-radius: 3px"
            >
              <v-layout row wrap justify-space-around="">
                <v-flex
                  xs12
                  class="text-center"
                  v-if="paidAllMoneyFromYesterday.length > 0"
                >
                  <h3 class="red--text">Today paid from yesterday</h3>
                  <span
                    v-for="(paidFromYesterday, i) in paidAllMoneyFromYesterday"
                    :key="i"
                  >
                    <strong> {{ paidFromYesterday.cashierName }}:</strong>
                    {{ paidFromYesterday.paidMoney }} Birr
                    <br />
                  </span>
                </v-flex>
                <v-flex xs12 md5>
                  <div id="chooseDate" class="ml-10 mr-10 pl-10">
                    <h4 class="mt-6 grey--text text--darken-1 ml-10">
                      Select All Report Date
                    </h4>

                    <v-menu rounded="lg" offset-y>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          text
                          class="pl-6 pr-6 mt-2"
                          v-bind="attrs"
                          v-on="on"
                          outlined=""
                        >
                          {{ spinChoosedDateRange }}
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item-group
                          v-model="spinGroup"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <v-list-item
                            v-for="item in items"
                            :key="item"
                            @click="
                              if (item == 'Custom Range') {
                                dateDialog = true;
                              } else {
                                getDailyWorks(item);
                                spinGetDailyWorks(item);
                              }
                            "
                          >
                            <v-list-item-title
                              v-text="item"
                            ></v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                class="mb-15 pa-3 pt-0"
                style="font-size: 14px"
              >
                <horizontal-scroll
                  class="horizontal-scroll white pa-3 pl-0 pt-0"
                  id="report"
                >
                  <table
                    style="width: 100%"
                    class="ma-3 mt-2"
                    v-if="
                      spinChoosenDate == 'Today' ||
                      spinChoosenDate == 'Yesterday' ||
                      spinChoosenDate == 'Last 15 days'
                    "
                  >
                    <tr>
                      <th>Day</th>
                      <th>Full Name</th>
                      <th>No Tickets</th>
                      <th>No Paid Tickets</th>
                      <th>No Cancelled Tickets</th>
                      <th>No Bets</th>
                      <th>Total Money</th>
                      <th>Paid Money</th>
                      <th>On Hand</th>
                    </tr>

                    <tr v-for="(shift, i) in allDailyReport" :key="i">
                      <td style="font-size: 11px">
                        <v-icon small>date_range</v-icon>
                        {{ weekday[shift.day] }}
                        <br v-if="shift.date != ''" />
                        <span class="ml-4">{{ shift.date }}</span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length"
                        >
                          {{ cashier.fullName }}
                          <br />
                        </span>
                      </td>

                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 1001"
                        >
                          {{ cashier.noTicket }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 10000"
                        >
                          {{ cashier.noPaidTicket }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 100000"
                        >
                          {{ cashier.nocancelledTicket }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 1000000"
                        >
                          {{ cashier.noBet }}
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 100"
                        >
                          {{ cashier.totalMoney }}
                          <span style="font-size: 11px">ETB</span>
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 200"
                        >
                          {{ cashier.paidMoney }}
                          <span style="font-size: 11px">ETB</span>
                          <br />
                        </span>
                      </td>
                      <td>
                        <span
                          v-for="(cashier, j) in shift.cashier"
                          :key="j + spinDailyReport.length + 300"
                        >
                          {{ cashier.onHandMoney }}
                          <span style="font-size: 11px">ETB</span>
                          <br />
                        </span>
                      </td>
                    </tr>
                  </table>

                  <table style="width: 100%" class="ma-3 mt-2" v-else>
                    <tr>
                      <th>Day</th>
                      <th>Full Name</th>
                      <th>No Tickets</th>
                      <th>No Paid Tickets</th>
                      <th>No Cancelled Tickets</th>
                      <th>No Bets</th>
                      <th>Total Money</th>
                      <th>Paid Money</th>
                      <th>On Hand</th>
                    </tr>

                    <tr v-for="(shift, i) in allDailyReport" :key="i">
                      <td style="font-size: 11px">
                        <v-icon small>date_range</v-icon>
                      </td>
                      <td>
                        <span>
                          {{ shift.fullName }}
                        </span>
                      </td>

                      <td>
                        <span>
                          {{ shift.noTicket }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.noPaidTicket }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.nocancelledTicket }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.noBet }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.totalMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.paidMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ shift.onHandMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </horizontal-scroll>
              </v-layout>
            </div>
          </div>

          <v-dialog v-model="dateDialog" persistent max-width="420">
            <v-card class="">
              <v-card-title>
                Choose date range <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="blue"
                  text
                  @click="
                    dateDialog = false;
                    if (date1 != '' && date2 != '') {
                      if ($store.state.whichToShow == 'keno') {
                        getDailyWorks('Custom Range');
                      } else if ($store.state.whichToShow == 'spin') {
                        spinGetDailyWorks('Custom Range');
                      } else {
                        getDailyWorks('Custom Range');
                        spinGetDailyWorks('Custom Range');
                      }
                    }
                  "
                >
                  GO
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date1"
                          label="Date 1"
                          hint="YYYY-MM-DD format"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date1"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" lg="6">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date2"
                          label="Date 2"
                          hint="YYYY-MM-DD format"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date2"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <div v-else class="mt-16">
          <p v-if="!pageLoading" class="headline red--text text-center">
            Reachs maximum day, Please contact the admin
          </p>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      pageLoading: false,
      choosedDateRange: "",
      loadingCount: 0,

      dateDialog: false,

      date1: "",
      date2: "",
      menu1: false,
      menu2: false,

      dailyWork: [],
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],

      dailyReport: [],
      choosenDate: "Last 15 days",

      items: [
        "Today",
        "Yesterday",
        "Last 15 days",
        "Last 7 days",
        "This Month",
        "Last Month",
        "This Year",
        "Custom Range",
      ],
      group: null,

      spinChoosedDateRange: "",

      spinDailyWork: [],
      spinDailyReport: [],
      allDailyReport: [],
      spinChoosenDate: "Last 15 days",

      spinGroup: null,
      paidSpinMoneyFromYesterday: [],
      paidKenoMoneyFromYesterday: [],
      paidAllMoneyFromYesterday: [],
    };
  },

  methods: {
    async getDailyWorks(passedDate) {
      var today = new Date();
      this.choosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.choosenDate == "Last 15 days") {
        firstDay = new Date(today.setDate(today.getDate() - 14));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.choosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.choosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.choosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.choosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.choosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.choosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else if (this.choosenDate == "Custom Range") {
        date2 = new Date(this.date2).getTime();
        this.choosedDateRange = this.date1 + " - " + this.date2;
        date1 = new Date(this.date1).getTime();
      }

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var dailyResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getDailyWorksWithOutCashOut(date1:"${date1}", date2:"${date2}"){
                       ticket_id
                       game_id
                       user_id
                       choosen_numbers
                       others
                       ticket_date
                       winner_award
                       is_cancelled
                       is_cashout
                       getCashier{
                           cashier_id
                           cashier_full_name
                           cashier_phone
                           cashier_address
                           user_name
                           is_active
                         }
                        getGameForShifts{
                         game_id
                         game_date

                       }

                   }
              }
              `,
          },
        });
        this.dailyWork = dailyResult.data.data.getDailyWorksWithOutCashOut;

        var allDays = [];
        var allCashiers = [];
        this.dailyReport = [];

        for (let t = 0; t < this.dailyWork.length; t++) {
          var day = new Date(Number(this.dailyWork[t].ticket_date)).getDay();

          if (allDays.indexOf(day) === -1) allDays.push(day);

          var cashier = this.dailyWork[t].getCashier[0].cashier_full_name;
          if (allCashiers.indexOf(cashier) === -1) allCashiers.push(cashier);
        }

        if (
          this.choosenDate == "Today" ||
          this.choosenDate == "Yesterday" ||
          this.choosenDate == "Last 15 days"
        ) {
          for (let d = 0; d < allDays.length; d++) {
            var eachCashier = [];
            var date = "";

            for (let c = 0; c < allCashiers.length; c++) {
              var totalMoneyForEach = 0;
              var totalPaidForEach = 0;
              var noTicket = 0;
              var nobet = 0;
              var noPaidTicket = 0;
              var nocancelledTicket = 0;
              var notCashoutTotalMoney = 0;
              var notCashoutPaidMoney = 0;

              for (let t = 0; t < this.dailyWork.length; t++) {
                var day = new Date(
                  Number(this.dailyWork[t].ticket_date)
                ).getDay();
                var cashierName =
                  this.dailyWork[t].getCashier[0].cashier_full_name;

                if (day == allDays[d] && cashierName == allCashiers[c]) {
                  var month =
                    this.monthNames[
                      new Date(Number(this.dailyWork[t].ticket_date)).getMonth()
                    ];

                  var eachSplitedDate = this.formatDate(
                    new Date(Number(this.dailyWork[t].ticket_date))
                  ).split("-");
                  date =
                    month + " " + eachSplitedDate[2] + "/" + eachSplitedDate[0];

                  var tickets = this.dailyWork[t].choosen_numbers.split(":");
                  var others = this.dailyWork[t].others.split(":");

                  if (this.dailyWork[t].is_cancelled == 0) {
                    noTicket = noTicket + 1;

                    if (tickets[0] != "") {
                      nobet = nobet + tickets.length;
                      for (let j = 0; j < tickets.length; j++) {
                        totalMoneyForEach =
                          totalMoneyForEach +
                          parseFloat(tickets[j].split("*")[1]);

                        if (this.choosenDate == "Today") {
                          notCashoutTotalMoney =
                            notCashoutTotalMoney +
                            parseFloat(tickets[j].split("*")[1]);
                        }
                      }
                    }

                    if (others[0] != "") {
                      nobet = nobet + others.length;
                      for (let k = 0; k < others.length; k++) {
                        totalMoneyForEach =
                          totalMoneyForEach +
                          parseFloat(others[k].split("*")[1]);

                        if (this.choosenDate == "Today") {
                          notCashoutTotalMoney =
                            notCashoutTotalMoney +
                            parseFloat(others[k].split("*")[1]);
                        }
                      }
                    }
                  } else nocancelledTicket = nocancelledTicket + 1;

                  if (this.dailyWork[t].winner_award != 0)
                    noPaidTicket = noPaidTicket + 1;
                  totalPaidForEach =
                    totalPaidForEach + this.dailyWork[t].winner_award;

                  if (
                    this.choosenDate == "Today" &&
                    this.dailyWork[t].is_cancelled == 0
                  ) {
                    notCashoutPaidMoney =
                      notCashoutPaidMoney + this.dailyWork[t].winner_award;
                  }
                }
              }

              eachCashier.push({
                fullName: allCashiers[c],
                noTicket: noTicket,
                noPaidTicket: noPaidTicket,
                nocancelledTicket: nocancelledTicket,
                noBet: nobet,

                totalMoney: totalMoneyForEach,
                paidMoney: totalPaidForEach,
                onHandMoney: totalMoneyForEach - totalPaidForEach,
              });
            }

            this.dailyReport.push({
              day: allDays[d],
              date: date,
              cashier: eachCashier,
            });
          }

          if (this.choosenDate == "Today" && this.dailyReport.length > 0) {
            for (let to = 0; to < this.dailyReport[0].cashier.length; to++) {
              for (
                let fn = 0;
                fn < this.paidKenoMoneyFromYesterday.length;
                fn++
              ) {
                if (
                  this.dailyReport[0].cashier[to].fullName ==
                  this.paidKenoMoneyFromYesterday[fn].cashierName
                ) {
                  this.dailyReport[0].cashier[to].paidMoney =
                    this.dailyReport[0].cashier[to].paidMoney +
                    this.paidKenoMoneyFromYesterday[fn].paidMoney;

                  this.dailyReport[0].cashier[to].onHandMoney =
                    this.dailyReport[0].cashier[to].onHandMoney -
                    this.paidKenoMoneyFromYesterday[fn].paidMoney;
                }
              }
            }
          }

          this.allDailyReport = [];
          for (let a = 0; a < this.dailyReport.length; a++) {
            let matchDayCount = 0;
            var eachWorker = [];
            for (let b = 0; b < this.spinDailyReport.length; b++) {
              if (
                this.dailyReport[a].day == this.spinDailyReport[b].day &&
                this.dailyReport[a].date == this.spinDailyReport[b].date
              ) {
                matchDayCount++;
                for (let c = 0; c < this.dailyReport[a].cashier.length; c++) {
                  let matchCashierCount = 0;
                  for (
                    let d = 0;
                    d < this.spinDailyReport[b].cashier.length;
                    d++
                  ) {
                    if (
                      this.dailyReport[a].cashier[c].fullName ==
                      this.spinDailyReport[b].cashier[d].fullName
                    ) {
                      matchCashierCount++;
                      eachWorker.push({
                        fullName: this.dailyReport[a].cashier[c].fullName,
                        noTicket:
                          this.dailyReport[a].cashier[c].noTicket +
                          this.spinDailyReport[b].cashier[d].noTicket,
                        noPaidTicket:
                          this.dailyReport[a].cashier[c].noPaidTicket +
                          this.spinDailyReport[b].cashier[d].noPaidTicket,
                        nocancelledTicket:
                          this.dailyReport[a].cashier[c].nocancelledTicket +
                          this.spinDailyReport[b].cashier[d].nocancelledTicket,
                        noBet:
                          this.dailyReport[a].cashier[c].noBet +
                          this.spinDailyReport[b].cashier[d].noBet,

                        totalMoney:
                          this.dailyReport[a].cashier[c].totalMoney +
                          this.spinDailyReport[b].cashier[d].totalMoney,
                        paidMoney:
                          this.dailyReport[a].cashier[c].paidMoney +
                          this.spinDailyReport[b].cashier[d].paidMoney,
                        onHandMoney:
                          this.dailyReport[a].cashier[c].onHandMoney +
                          this.spinDailyReport[b].cashier[d].onHandMoney,
                      });
                    }
                  }
                  if (matchCashierCount == 0) {
                    eachWorker.push({
                      fullName: this.dailyReport[a].cashier[c].fullName,
                      noTicket: this.dailyReport[a].cashier[c].noTicket,
                      noPaidTicket: this.dailyReport[a].cashier[c].noPaidTicket,
                      nocancelledTicket:
                        this.dailyReport[a].cashier[c].nocancelledTicket,
                      noBet: this.dailyReport[a].cashier[c].noBet,

                      totalMoney: this.dailyReport[a].cashier[c].totalMoney,
                      paidMoney: this.dailyReport[a].cashier[c].paidMoney,
                      onHandMoney: this.dailyReport[a].cashier[c].onHandMoney,
                    });
                  }
                }
              }
            }

            if (matchDayCount == 0) {
              this.allDailyReport.push({
                day: this.dailyReport[a].day,
                date: this.dailyReport[a].date,
                cashier: this.dailyReport[a].cashier,
              });
            } else if (eachWorker.length > 0) {
              this.allDailyReport.push({
                day: this.dailyReport[a].day,
                date: this.dailyReport[a].date,
                cashier: eachWorker,
              });
            }
          }

          for (let a = 0; a < this.spinDailyReport.length; a++) {
            let matchDayCount = 0;
            var eachWorker = [];
            for (let b = 0; b < this.dailyReport.length; b++) {
              if (
                this.spinDailyReport[a].day == this.dailyReport[b].day &&
                this.spinDailyReport[a].date == this.dailyReport[b].date
              ) {
                matchDayCount++;
                for (
                  let c = 0;
                  c < this.spinDailyReport[a].cashier.length;
                  c++
                ) {
                  let matchCashierCount = 0;
                  for (let d = 0; d < this.dailyReport[b].cashier.length; d++) {
                    if (
                      this.spinDailyReport[a].cashier[c].fullName ==
                      this.dailyReport[b].cashier[d].fullName
                    ) {
                      matchCashierCount++;
                    }
                  }
                  if (matchCashierCount == 0) {
                    eachWorker.push({
                      fullName: this.spinDailyReport[a].cashier[c].fullName,
                      noTicket: this.spinDailyReport[a].cashier[c].noTicket,
                      noPaidTicket:
                        this.spinDailyReport[a].cashier[c].noPaidTicket,
                      nocancelledTicket:
                        this.spinDailyReport[a].cashier[c].nocancelledTicket,
                      noBet: this.spinDailyReport[a].cashier[c].noBet,

                      totalMoney: this.spinDailyReport[a].cashier[c].totalMoney,
                      paidMoney: this.spinDailyReport[a].cashier[c].paidMoney,
                      onHandMoney:
                        this.spinDailyReport[a].cashier[c].onHandMoney,
                    });
                  }
                }
              }
            }

            if (matchDayCount == 0) {
              this.allDailyReport.push({
                day: this.spinDailyReport[a].day,
                date: this.spinDailyReport[a].date,
                cashier: this.spinDailyReport[a].cashier,
              });
            } else if (eachWorker.length > 0) {
              this.allDailyReport.push({
                day: this.spinDailyReport[a].day,
                date: this.spinDailyReport[a].date,
                cashier: eachWorker,
              });
            }
          }
        } else {
          this.dailyReport = [];

          for (let c = 0; c < allCashiers.length; c++) {
            var totalMoneyForEach = 0;
            var totalPaidForEach = 0;
            var noTicket = 0;
            var nobet = 0;
            var noPaidTicket = 0;
            var nocancelledTicket = 0;
            var notCashoutTotalMoney = 0;
            var notCashoutPaidMoney = 0;

            for (let t = 0; t < this.dailyWork.length; t++) {
              var cashierName =
                this.dailyWork[t].getCashier[0].cashier_full_name;

              if (cashierName == allCashiers[c]) {
                var tickets = this.dailyWork[t].choosen_numbers.split(":");
                var others = this.dailyWork[t].others.split(":");

                if (this.dailyWork[t].is_cancelled == 0) {
                  noTicket = noTicket + 1;

                  if (tickets[0] != "") {
                    nobet = nobet + tickets.length;
                    for (let j = 0; j < tickets.length; j++) {
                      totalMoneyForEach =
                        totalMoneyForEach +
                        parseFloat(tickets[j].split("*")[1]);

                      if (this.dailyWork[t].is_cashout == 0) {
                        notCashoutTotalMoney =
                          notCashoutTotalMoney +
                          parseFloat(tickets[j].split("*")[1]);
                      }
                    }
                  }

                  if (others[0] != "") {
                    nobet = nobet + others.length;
                    for (let k = 0; k < others.length; k++) {
                      totalMoneyForEach =
                        totalMoneyForEach + parseFloat(others[k].split("*")[1]);

                      if (this.dailyWork[t].is_cashout == 0) {
                        notCashoutTotalMoney =
                          notCashoutTotalMoney +
                          parseFloat(others[k].split("*")[1]);
                      }
                    }
                  }
                } else nocancelledTicket = nocancelledTicket + 1;

                if (this.dailyWork[t].winner_award != 0)
                  noPaidTicket = noPaidTicket + 1;
                totalPaidForEach =
                  totalPaidForEach + this.dailyWork[t].winner_award;

                if (this.dailyWork[t].is_cashout == 0) {
                  notCashoutPaidMoney =
                    notCashoutPaidMoney + this.dailyWork[t].winner_award;
                }
              }
            }

            this.dailyReport.push({
              fullName: allCashiers[c],
              noTicket: noTicket,
              noPaidTicket: noPaidTicket,
              nocancelledTicket: nocancelledTicket,
              noBet: nobet,

              totalMoney: totalMoneyForEach,
              paidMoney: totalPaidForEach,
              onHandMoney: totalMoneyForEach - totalPaidForEach,
            });
          }

          this.allDailyReport = [];

          for (let c = 0; c < this.dailyReport.length; c++) {
            let matchCashierCount = 0;
            for (let d = 0; d < this.spinDailyReport.length; d++) {
              if (
                this.dailyReport[c].fullName == this.spinDailyReport[d].fullName
              ) {
                matchCashierCount++;
                this.allDailyReport.push({
                  fullName: this.dailyReport[c].fullName,
                  noTicket:
                    this.dailyReport[c].noTicket +
                    this.spinDailyReport[d].noTicket,
                  noPaidTicket:
                    this.dailyReport[c].noPaidTicket +
                    this.spinDailyReport[d].noPaidTicket,
                  nocancelledTicket:
                    this.dailyReport[c].nocancelledTicket +
                    this.spinDailyReport[d].nocancelledTicket,
                  noBet:
                    this.dailyReport[c].noBet + this.spinDailyReport[d].noBet,

                  totalMoney:
                    this.dailyReport[c].totalMoney +
                    this.spinDailyReport[d].totalMoney,
                  paidMoney:
                    this.dailyReport[c].paidMoney +
                    this.spinDailyReport[d].paidMoney,
                  onHandMoney:
                    this.dailyReport[c].onHandMoney +
                    this.spinDailyReport[d].onHandMoney,
                });
              }
            }
            if (matchCashierCount == 0) {
              this.allDailyReport.push({
                fullName: this.dailyReport[c].fullName,
                noTicket: this.dailyReport[c].noTicket,
                noPaidTicket: this.dailyReport[c].noPaidTicket,
                nocancelledTicket: this.dailyReport[c].nocancelledTicket,
                noBet: this.dailyReport[c].noBet,

                totalMoney: this.dailyReport[c].totalMoney,
                paidMoney: this.dailyReport[c].paidMoney,
                onHandMoney: this.dailyReport[c].onHandMoney,
              });
            }
          }

          for (let c = 0; c < this.spinDailyReport.length; c++) {
            let matchCashierCount = 0;
            for (let d = 0; d < this.dailyReport.length; d++) {
              if (
                this.dailyReport[d].fullName == this.spinDailyReport[c].fullName
              ) {
                matchCashierCount++;
              }
            }
            if (matchCashierCount == 0) {
              this.allDailyReport.push({
                fullName: this.spinDailyReport[c].fullName,
                noTicket: this.spinDailyReport[c].noTicket,
                noPaidTicket: this.spinDailyReport[c].noPaidTicket,
                nocancelledTicket: this.spinDailyReport[c].nocancelledTicket,
                noBet: this.spinDailyReport[c].noBet,

                totalMoney: this.spinDailyReport[c].totalMoney,
                paidMoney: this.spinDailyReport[c].paidMoney,
                onHandMoney: this.spinDailyReport[c].onHandMoney,
              });
            }
          }
        }
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },
    async spinGetDailyWorks(passedDate) {
      var today = new Date();
      this.spinChoosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.spinChoosenDate == "Last 15 days") {
        firstDay = new Date(today.setDate(today.getDate() - 14));
        date2 = Date.now();

        this.spinChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.spinChoosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.spinChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.spinChoosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.spinChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.spinChoosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.spinChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.spinChoosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.spinChoosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.spinChoosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.spinChoosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.spinChoosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.spinChoosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else if (this.spinChoosenDate == "Custom Range") {
        date2 = new Date(this.date2).getTime();
        this.spinChoosedDateRange = this.date1 + " - " + this.date2;
        date1 = new Date(this.date1).getTime();
      }

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var dailyResult = await axios({
          method: "POST",
          url: this.$store.state.spinPartnerURL,

          data: {
            query: `{
               getDailyWorksWithOutCashOut(date1:"${date1}", date2:"${date2}"){
                       ticket_id
                       game_id
                       user_id
                       choosen_numbers
                       others
                       ticket_date
                       winner_award
                       is_cancelled
                       is_cashout
                       getCashier{
                           cashier_id
                           cashier_full_name
                           cashier_phone
                           cashier_address
                           user_name
                           is_active
                         }
                        getGameForShifts{
                           game_id
                           game_date
                                                 

                       }

                   }
              }
              `,
          },
        });
        this.spinDailyWork = dailyResult.data.data.getDailyWorksWithOutCashOut;

        var allDays = [];
        var allCashiers = [];
        this.spinDailyReport = [];

        for (let t = 0; t < this.spinDailyWork.length; t++) {
          var day = new Date(
            Number(this.spinDailyWork[t].ticket_date)
          ).getDay();

          if (allDays.indexOf(day) === -1) allDays.push(day);

          var cashier = this.spinDailyWork[t].getCashier[0].cashier_full_name;
          if (allCashiers.indexOf(cashier) === -1) allCashiers.push(cashier);
        }

        if (
          this.spinChoosenDate == "Today" ||
          this.spinChoosenDate == "Yesterday" ||
          this.spinChoosenDate == "Last 15 days"
        ) {
          for (let d = 0; d < allDays.length; d++) {
            var eachCashier = [];
            var date = "";

            for (let c = 0; c < allCashiers.length; c++) {
              var totalMoneyForEach = 0;
              var totalPaidForEach = 0;
              var noTicket = 0;
              var nobet = 0;
              var noPaidTicket = 0;
              var nocancelledTicket = 0;
              var notCashoutTotalMoney = 0;
              var notCashoutPaidMoney = 0;

              for (let t = 0; t < this.spinDailyWork.length; t++) {
                var day = new Date(
                  Number(this.spinDailyWork[t].ticket_date)
                ).getDay();
                var cashierName =
                  this.spinDailyWork[t].getCashier[0].cashier_full_name;

                if (day == allDays[d] && cashierName == allCashiers[c]) {
                  var month =
                    this.monthNames[
                      new Date(
                        Number(this.spinDailyWork[t].ticket_date)
                      ).getMonth()
                    ];

                  var eachSplitedDate = this.formatDate(
                    new Date(Number(this.spinDailyWork[t].ticket_date))
                  ).split("-");
                  date =
                    month + " " + eachSplitedDate[2] + "/" + eachSplitedDate[0];

                  var tickets =
                    this.spinDailyWork[t].choosen_numbers.split(":");

                  if (this.spinDailyWork[t].is_cancelled == 0) {
                    noTicket = noTicket + 1;

                    if (tickets[0] != "") {
                      nobet = nobet + tickets.length;
                      for (let j = 0; j < tickets.length; j++) {
                        totalMoneyForEach =
                          totalMoneyForEach +
                          parseFloat(tickets[j].split("*")[1]);

                        if (this.spinChoosenDate == "Today") {
                          notCashoutTotalMoney =
                            notCashoutTotalMoney +
                            parseFloat(tickets[j].split("*")[1]);
                        }
                      }
                    }
                  } else nocancelledTicket = nocancelledTicket + 1;

                  if (this.spinDailyWork[t].winner_award != 0)
                    noPaidTicket = noPaidTicket + 1;
                  totalPaidForEach =
                    totalPaidForEach + this.spinDailyWork[t].winner_award;

                  if (
                    this.spinChoosenDate == "Today" &&
                    this.spinDailyWork[t].is_cancelled == 0
                  ) {
                    notCashoutPaidMoney =
                      notCashoutPaidMoney + this.spinDailyWork[t].winner_award;
                  }
                }
              }

              eachCashier.push({
                fullName: allCashiers[c],
                noTicket: noTicket,
                noPaidTicket: noPaidTicket,
                nocancelledTicket: nocancelledTicket,
                noBet: nobet,

                totalMoney: totalMoneyForEach,
                paidMoney: totalPaidForEach,
                onHandMoney: totalMoneyForEach - totalPaidForEach,
              });
            }

            this.spinDailyReport.push({
              day: allDays[d],
              date: date,
              cashier: eachCashier,
            });
          }

          if (
            this.spinChoosenDate == "Today" &&
            this.spinDailyReport.length > 0
          ) {
            for (
              let to = 0;
              to < this.spinDailyReport[0].cashier.length;
              to++
            ) {
              for (
                let fn = 0;
                fn < this.paidSpinMoneyFromYesterday.length;
                fn++
              ) {
                if (
                  this.spinDailyReport[0].cashier[to].fullName ==
                  this.paidSpinMoneyFromYesterday[fn].cashierName
                ) {
                  this.spinDailyReport[0].cashier[to].paidMoney =
                    this.spinDailyReport[0].cashier[to].paidMoney +
                    this.paidSpinMoneyFromYesterday[fn].paidMoney;

                  this.spinDailyReport[0].cashier[to].onHandMoney =
                    this.spinDailyReport[0].cashier[to].onHandMoney -
                    this.paidSpinMoneyFromYesterday[fn].paidMoney;
                }
              }
            }
          }

          this.allDailyReport = [];
          for (let a = 0; a < this.dailyReport.length; a++) {
            let matchDayCount = 0;
            var eachWorker = [];
            for (let b = 0; b < this.spinDailyReport.length; b++) {
              if (
                this.dailyReport[a].day == this.spinDailyReport[b].day &&
                this.dailyReport[a].date == this.spinDailyReport[b].date
              ) {
                matchDayCount++;
                for (let c = 0; c < this.dailyReport[a].cashier.length; c++) {
                  let matchCashierCount = 0;
                  for (
                    let d = 0;
                    d < this.spinDailyReport[b].cashier.length;
                    d++
                  ) {
                    if (
                      this.dailyReport[a].cashier[c].fullName ==
                      this.spinDailyReport[b].cashier[d].fullName
                    ) {
                      matchCashierCount++;
                      eachWorker.push({
                        fullName: this.dailyReport[a].cashier[c].fullName,
                        noTicket:
                          this.dailyReport[a].cashier[c].noTicket +
                          this.spinDailyReport[b].cashier[d].noTicket,
                        noPaidTicket:
                          this.dailyReport[a].cashier[c].noPaidTicket +
                          this.spinDailyReport[b].cashier[d].noPaidTicket,
                        nocancelledTicket:
                          this.dailyReport[a].cashier[c].nocancelledTicket +
                          this.spinDailyReport[b].cashier[d].nocancelledTicket,
                        noBet:
                          this.dailyReport[a].cashier[c].noBet +
                          this.spinDailyReport[b].cashier[d].noBet,

                        totalMoney:
                          this.dailyReport[a].cashier[c].totalMoney +
                          this.spinDailyReport[b].cashier[d].totalMoney,
                        paidMoney:
                          this.dailyReport[a].cashier[c].paidMoney +
                          this.spinDailyReport[b].cashier[d].paidMoney,
                        onHandMoney:
                          this.dailyReport[a].cashier[c].onHandMoney +
                          this.spinDailyReport[b].cashier[d].onHandMoney,
                      });
                    }
                  }
                  if (matchCashierCount == 0) {
                    eachWorker.push({
                      fullName: this.dailyReport[a].cashier[c].fullName,
                      noTicket: this.dailyReport[a].cashier[c].noTicket,
                      noPaidTicket: this.dailyReport[a].cashier[c].noPaidTicket,
                      nocancelledTicket:
                        this.dailyReport[a].cashier[c].nocancelledTicket,
                      noBet: this.dailyReport[a].cashier[c].noBet,

                      totalMoney: this.dailyReport[a].cashier[c].totalMoney,
                      paidMoney: this.dailyReport[a].cashier[c].paidMoney,
                      onHandMoney: this.dailyReport[a].cashier[c].onHandMoney,
                    });
                  }
                }
              }
            }

            if (matchDayCount == 0) {
              this.allDailyReport.push({
                day: this.dailyReport[a].day,
                date: this.dailyReport[a].date,
                cashier: this.dailyReport[a].cashier,
              });
            } else if (eachWorker.length > 0) {
              this.allDailyReport.push({
                day: this.dailyReport[a].day,
                date: this.dailyReport[a].date,
                cashier: eachWorker,
              });
            }
          }

          for (let a = 0; a < this.spinDailyReport.length; a++) {
            let matchDayCount = 0;
            var eachWorker = [];
            for (let b = 0; b < this.dailyReport.length; b++) {
              if (
                this.spinDailyReport[a].day == this.dailyReport[b].day &&
                this.spinDailyReport[a].date == this.dailyReport[b].date
              ) {
                matchDayCount++;
                for (
                  let c = 0;
                  c < this.spinDailyReport[a].cashier.length;
                  c++
                ) {
                  let matchCashierCount = 0;
                  for (let d = 0; d < this.dailyReport[b].cashier.length; d++) {
                    if (
                      this.spinDailyReport[a].cashier[c].fullName ==
                      this.dailyReport[b].cashier[d].fullName
                    ) {
                      matchCashierCount++;
                    }
                  }
                  if (matchCashierCount == 0) {
                    eachWorker.push({
                      fullName: this.spinDailyReport[a].cashier[c].fullName,
                      noTicket: this.spinDailyReport[a].cashier[c].noTicket,
                      noPaidTicket:
                        this.spinDailyReport[a].cashier[c].noPaidTicket,
                      nocancelledTicket:
                        this.spinDailyReport[a].cashier[c].nocancelledTicket,
                      noBet: this.spinDailyReport[a].cashier[c].noBet,

                      totalMoney: this.spinDailyReport[a].cashier[c].totalMoney,
                      paidMoney: this.spinDailyReport[a].cashier[c].paidMoney,
                      onHandMoney:
                        this.spinDailyReport[a].cashier[c].onHandMoney,
                    });
                  }
                }
              }
            }

            if (matchDayCount == 0) {
              this.allDailyReport.push({
                day: this.spinDailyReport[a].day,
                date: this.spinDailyReport[a].date,
                cashier: this.spinDailyReport[a].cashier,
              });
            } else if (eachWorker.length > 0) {
              this.allDailyReport.push({
                day: this.spinDailyReport[a].day,
                date: this.spinDailyReport[a].date,
                cashier: eachWorker,
              });
            }
          }
        } else {
          this.spinDailyReport = [];

          for (let c = 0; c < allCashiers.length; c++) {
            var totalMoneyForEach = 0;
            var totalPaidForEach = 0;
            var noTicket = 0;
            var nobet = 0;
            var noPaidTicket = 0;
            var nocancelledTicket = 0;
            var notCashoutTotalMoney = 0;
            var notCashoutPaidMoney = 0;

            for (let t = 0; t < this.spinDailyWork.length; t++) {
              var cashierName =
                this.spinDailyWork[t].getCashier[0].cashier_full_name;

              if (cashierName == allCashiers[c]) {
                var tickets = this.spinDailyWork[t].choosen_numbers.split(":");

                if (this.spinDailyWork[t].is_cancelled == 0) {
                  noTicket = noTicket + 1;

                  if (tickets[0] != "") {
                    nobet = nobet + tickets.length;
                    for (let j = 0; j < tickets.length; j++) {
                      totalMoneyForEach =
                        totalMoneyForEach +
                        parseFloat(tickets[j].split("*")[1]);

                      if (this.spinDailyWork[t].is_cashout == 0) {
                        notCashoutTotalMoney =
                          notCashoutTotalMoney +
                          parseFloat(tickets[j].split("*")[1]);
                      }
                    }
                  }
                } else nocancelledTicket = nocancelledTicket + 1;

                if (this.spinDailyWork[t].winner_award != 0)
                  noPaidTicket = noPaidTicket + 1;
                totalPaidForEach =
                  totalPaidForEach + this.spinDailyWork[t].winner_award;

                if (this.spinDailyWork[t].is_cashout == 0) {
                  notCashoutPaidMoney =
                    notCashoutPaidMoney + this.spinDailyWork[t].winner_award;
                }
              }
            }

            this.spinDailyReport.push({
              fullName: allCashiers[c],
              noTicket: noTicket,
              noPaidTicket: noPaidTicket,
              nocancelledTicket: nocancelledTicket,
              noBet: nobet,

              totalMoney: totalMoneyForEach,
              paidMoney: totalPaidForEach,
              onHandMoney: totalMoneyForEach - totalPaidForEach,
            });
          }

          this.allDailyReport = [];

          for (let c = 0; c < this.dailyReport.length; c++) {
            let matchCashierCount = 0;
            for (let d = 0; d < this.spinDailyReport.length; d++) {
              if (
                this.dailyReport[c].fullName == this.spinDailyReport[d].fullName
              ) {
                matchCashierCount++;
                this.allDailyReport.push({
                  fullName: this.dailyReport[c].fullName,
                  noTicket:
                    this.dailyReport[c].noTicket +
                    this.spinDailyReport[d].noTicket,
                  noPaidTicket:
                    this.dailyReport[c].noPaidTicket +
                    this.spinDailyReport[d].noPaidTicket,
                  nocancelledTicket:
                    this.dailyReport[c].nocancelledTicket +
                    this.spinDailyReport[d].nocancelledTicket,
                  noBet:
                    this.dailyReport[c].noBet + this.spinDailyReport[d].noBet,

                  totalMoney:
                    this.dailyReport[c].totalMoney +
                    this.spinDailyReport[d].totalMoney,
                  paidMoney:
                    this.dailyReport[c].paidMoney +
                    this.spinDailyReport[d].paidMoney,
                  onHandMoney:
                    this.dailyReport[c].onHandMoney +
                    this.spinDailyReport[d].onHandMoney,
                });
              }
            }
            if (matchCashierCount == 0) {
              this.allDailyReport.push({
                fullName: this.dailyReport[c].fullName,
                noTicket: this.dailyReport[c].noTicket,
                noPaidTicket: this.dailyReport[c].noPaidTicket,
                nocancelledTicket: this.dailyReport[c].nocancelledTicket,
                noBet: this.dailyReport[c].noBet,

                totalMoney: this.dailyReport[c].totalMoney,
                paidMoney: this.dailyReport[c].paidMoney,
                onHandMoney: this.dailyReport[c].onHandMoney,
              });
            }
          }

          for (let c = 0; c < this.spinDailyReport.length; c++) {
            let matchCashierCount = 0;
            for (let d = 0; d < this.dailyReport.length; d++) {
              if (
                this.dailyReport[d].fullName == this.spinDailyReport[c].fullName
              ) {
                matchCashierCount++;
              }
            }
            if (matchCashierCount == 0) {
              this.allDailyReport.push({
                fullName: this.spinDailyReport[c].fullName,
                noTicket: this.spinDailyReport[c].noTicket,
                noPaidTicket: this.spinDailyReport[c].noPaidTicket,
                nocancelledTicket: this.spinDailyReport[c].nocancelledTicket,
                noBet: this.spinDailyReport[c].noBet,

                totalMoney: this.spinDailyReport[c].totalMoney,
                paidMoney: this.spinDailyReport[c].paidMoney,
                onHandMoney: this.spinDailyReport[c].onHandMoney,
              });
            }
          }
        }
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    async getPaidFromYesterday() {
      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

      try {
        var paidMoneyFromYesterdayResult = await axios({
          method: "POST",
          url: this.$store.state.spinPartnerURL,

          data: {
            query: `{
              getMoneyFromYesterdayByAdmin(date1:"${date1}", date2:"${date2}"){
                    paid_from_yesterday_id
                    cashier_id
                    user_id
                    winner_award
                    paid_date
                    getCashier{
                    cashier_full_name
                   }
                   }
              }
              `,
          },
        });

        var paidMoneyFromYesterday =
          paidMoneyFromYesterdayResult.data.data.getMoneyFromYesterdayByAdmin;
        this.paidSpinMoneyFromYesterday = [];

        var cashiers = [];

        for (let k = 0; k < paidMoneyFromYesterday.length; k++) {
          cashiers.push(
            paidMoneyFromYesterday[k].getCashier[0].cashier_full_name
          );
        }

        let uniqueCashiers = [...new Set(cashiers)];

        for (let c = 0; c < uniqueCashiers.length; c++) {
          var eachPiad = 0;
          for (let k = 0; k < paidMoneyFromYesterday.length; k++) {
            if (
              uniqueCashiers[c] ==
              paidMoneyFromYesterday[k].getCashier[0].cashier_full_name
            ) {
              eachPiad = eachPiad + paidMoneyFromYesterday[k].winner_award;
            }
          }

          this.paidSpinMoneyFromYesterday.push({
            cashierName: uniqueCashiers[c],
            paidMoney: eachPiad,
          });
        }

        this.paidAllMoneyFromYesterday = [];
        for (let p = 0; p < this.paidKenoMoneyFromYesterday.length; p++) {
          let matchCount = 0;
          for (let p1 = 0; p1 < this.paidSpinMoneyFromYesterday.length; p1++) {
            if (
              this.paidKenoMoneyFromYesterday[p].cashierName ==
              this.paidSpinMoneyFromYesterday[p1].cashierName
            ) {
              matchCount++;

              this.paidAllMoneyFromYesterday.push({
                cashierName: this.paidKenoMoneyFromYesterday[p].cashierName,
                paidMoney:
                  this.paidKenoMoneyFromYesterday[p].paidMoney +
                  this.paidSpinMoneyFromYesterday[p1].paidMoney,
              });
            }
          }
          if (matchCount == 0) {
            this.paidAllMoneyFromYesterday.push({
              cashierName: this.paidKenoMoneyFromYesterday[p].cashierName,
              paidMoney: this.paidKenoMoneyFromYesterday[p].paidMoney,
            });
          }
        }

        for (let p = 0; p < this.paidSpinMoneyFromYesterday.length; p++) {
          let matchCount = 0;
          for (let p1 = 0; p1 < this.paidKenoMoneyFromYesterday.length; p1++) {
            if (
              this.paidKenoMoneyFromYesterday[p1].cashierName ==
              this.paidSpinMoneyFromYesterday[p].cashierName
            ) {
              matchCount++;
            }
          }
          if (matchCount == 0) {
            this.paidAllMoneyFromYesterday.push({
              cashierName: this.paidSpinMoneyFromYesterday[p].cashierName,
              paidMoney: this.paidSpinMoneyFromYesterday[p].paidMoney,
            });
          }
        }
      } catch (err) {}

      try {
        var paidMoneyKenoFromYesterdayResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
              getMoneyFromYesterdayByAdmin(date1:"${date1}", date2:"${date2}"){
                    paid_from_yesterday_id
                    cashier_id
                    user_id
                    winner_award
                    paid_date
                    getCashier{
                    cashier_full_name
                   }
                   }
              }
              `,
          },
        });

        var paidMoneyKenoFromYesterday =
          paidMoneyKenoFromYesterdayResult.data.data
            .getMoneyFromYesterdayByAdmin;
        this.paidKenoMoneyFromYesterday = [];

        var cashiers = [];

        for (let k = 0; k < paidMoneyKenoFromYesterday.length; k++) {
          cashiers.push(
            paidMoneyKenoFromYesterday[k].getCashier[0].cashier_full_name
          );
        }

        let uniqueCashiers = [...new Set(cashiers)];

        for (let c = 0; c < uniqueCashiers.length; c++) {
          var eachPiad = 0;
          for (let k = 0; k < paidMoneyKenoFromYesterday.length; k++) {
            if (
              uniqueCashiers[c] ==
              paidMoneyKenoFromYesterday[k].getCashier[0].cashier_full_name
            ) {
              eachPiad = eachPiad + paidMoneyKenoFromYesterday[k].winner_award;
            }
          }

          this.paidKenoMoneyFromYesterday.push({
            cashierName: uniqueCashiers[c],
            paidMoney: eachPiad,
          });
        }

        this.paidAllMoneyFromYesterday = [];
        for (let p = 0; p < this.paidKenoMoneyFromYesterday.length; p++) {
          let matchCount = 0;
          for (let p1 = 0; p1 < this.paidSpinMoneyFromYesterday.length; p1++) {
            if (
              this.paidKenoMoneyFromYesterday[p].cashierName ==
              this.paidSpinMoneyFromYesterday[p1].cashierName
            ) {
              matchCount++;

              this.paidAllMoneyFromYesterday.push({
                cashierName: this.paidKenoMoneyFromYesterday[p].cashierName,
                paidMoney:
                  this.paidKenoMoneyFromYesterday[p].paidMoney +
                  this.paidSpinMoneyFromYesterday[p1].paidMoney,
              });
            }
          }
          if (matchCount == 0) {
            this.paidAllMoneyFromYesterday.push({
              cashierName: this.paidKenoMoneyFromYesterday[p].cashierName,
              paidMoney: this.paidKenoMoneyFromYesterday[p].paidMoney,
            });
          }
        }

        for (let p = 0; p < this.paidSpinMoneyFromYesterday.length; p++) {
          let matchCount = 0;
          for (let p1 = 0; p1 < this.paidKenoMoneyFromYesterday.length; p1++) {
            if (
              this.paidKenoMoneyFromYesterday[p1].cashierName ==
              this.paidSpinMoneyFromYesterday[p].cashierName
            ) {
              matchCount++;
            }
          }
          if (matchCount == 0) {
            this.paidAllMoneyFromYesterday.push({
              cashierName: this.paidSpinMoneyFromYesterday[p].cashierName,
              paidMoney: this.paidSpinMoneyFromYesterday[p].paidMoney,
            });
          }
        }
      } catch (err) {}
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      await this.getPaidFromYesterday();
      await this.getDailyWorks("Today");
      await this.spinGetDailyWorks("Today");
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
