<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Game Analysis</p>
      <div
        v-if="$store.state.partner != '' && $store.state.partner.is_active"
        class=""
        style="font-size: 13px"
      >
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-if="$store.state.day_block > 0">
          <div
            v-if="!pageLoading"
            class="white pa-10 pl-1 pr-1"
            id="report"
            style="border-radius: 3px"
          >
            <div v-if="$store.state.whichToShow == 'keno'">
              <v-layout row wrap justify-space-around="">
                <v-flex xs12 md5>
                  <div id="chooseDate" class="ml-10 mr-10 pl-10">
                    <h3 class="mt-6 grey--text text--darken-1 ml-10">
                      Today's Keno Game Analysis
                    </h3>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                class="mb-15 pa-3 pt-0"
                style="font-size: 14px"
              >
                <horizontal-scroll
                  class="horizontal-scroll white pa-3 pl-0 pt-0"
                  id="report"
                >
                  <table style="width: 100%" class="ma-3 mt-2">
                    <tr>
                      <th>Date</th>
                      <th>Game#</th>
                      <th>No Tickets</th>
                      <th>No Paid Tickets</th>
                      <th>No Cancelled Tickets</th>
                      <th>No Bets</th>

                      <th>Total Money</th>
                      <th>Winner Money</th>
                      <!-- <th>Paid Money</th> -->
                      <th>Profit</th>
                    </tr>

                    <tr v-for="(game, i) in dailyReport" :key="i">
                      <td>
                        <span>
                          {{ new Date(Number(game.date)) | formatDate }}
                        </span>
                      </td>

                      <td>
                        <span>
                          {{ game.gameNo }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.totalTickets }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.totalPaidTickets }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.cancelledTickets }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.noBets }}
                        </span>
                      </td>

                      <td>
                        <span>
                          {{ game.totalMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.winnerMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <!-- <td>
                      <span>
                        {{ game.paidMoney }}
                        <span style="font-size: 11px">ETB</span>
                      </span>
                    </td> -->
                      <td>
                        <span>
                          {{ game.totalEarning }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </horizontal-scroll>
              </v-layout>
            </div>
            <div v-if="$store.state.whichToShow == 'spin'">
              <div
                v-if="!pageLoading"
                class="white pa-10 pl-1 pr-1"
                id="report"
                style="border-radius: 3px"
              >
                <v-layout row wrap justify-space-around="">
                  <v-flex xs12 md5>
                    <div id="chooseDate" class="ml-10 mr-10 pl-10">
                      <h3 class="mt-6 grey--text text--darken-1 ml-10">
                        Today's Spin Game Analysis
                      </h3>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap
                  class="mb-15 pa-3 pt-0"
                  style="font-size: 14px"
                >
                  <horizontal-scroll
                    class="horizontal-scroll white pa-3 pl-0 pt-0"
                    id="report"
                  >
                    <table style="width: 100%" class="ma-3 mt-2">
                      <tr>
                        <th>Date</th>
                        <th>Game#</th>
                        <th>No Tickets</th>
                        <th>No Paid Tickets</th>
                        <th>No Cancelled Tickets</th>
                        <th>No Bets</th>

                        <th>Total Money</th>
                        <th>Winner Money</th>
                        <!-- <th>Paid Money</th> -->
                        <th>Profit</th>
                      </tr>

                      <tr v-for="(game, i) in spinDailyReport" :key="i">
                        <td>
                          <span>
                            {{ new Date(Number(game.date)) | formatDate }}
                          </span>
                        </td>

                        <td>
                          <span>
                            {{ game.gameNo }}
                          </span>
                        </td>
                        <td>
                          <span>
                            {{ game.totalTickets }}
                          </span>
                        </td>
                        <td>
                          <span>
                            {{ game.totalPaidTickets }}
                          </span>
                        </td>
                        <td>
                          <span>
                            {{ game.cancelledTickets }}
                          </span>
                        </td>
                        <td>
                          <span>
                            {{ game.noBets }}
                          </span>
                        </td>

                        <td>
                          <span>
                            {{ game.totalMoney }}
                            <span style="font-size: 11px">ETB</span>
                          </span>
                        </td>
                        <td>
                          <span>
                            {{ game.winnerMoney }}
                            <span style="font-size: 11px">ETB</span>
                          </span>
                        </td>
                        <!-- <td>
                        <span>
                          {{ game.paidMoney }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td> -->
                        <td>
                          <span>
                            {{ game.totalEarning }}
                            <span style="font-size: 11px">ETB</span>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </horizontal-scroll>
                </v-layout>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-16">
          <p v-if="!pageLoading" class="headline red--text text-center">
            Reachs maximum day, Please contact the admin
          </p>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">
          You are blocked, please contact the admin
        </p>
        <!-- <a @click="$router.push({ name: 'login' })">login</a> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      pageLoading: false,
      loadingCount: 0,

      dailyWork: [],
      odds: [],
      dailyReport: [],

      spinDailyWork: [],
      spinOdds: [],
      spinDailyReport: [],
    };
  },

  methods: {
    async getTodaysGameAnalysis() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 8683989;

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var gameAnalysisResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
               getTodaysGameAnalysis(date1:"${date1}", date2:"${date2}"){
                   game_id                 
                   game_number
                   drawn_numbers
                   game_date
                   high_low
                   even_odd
                   game_status
                   getTickets {
                     ticket_id
                     game_id
                     user_id
                     choosen_numbers
                     others
                     ticket_date
                     winner_award
                     is_cancelled
                   }
                    
              }
              
              }
              `,
          },
        });
        this.dailyWork = gameAnalysisResult.data.data.getTodaysGameAnalysis;

        this.dailyReport = [];
        var rowOut2 = this.odds;

        for (let i = 0; i < this.dailyWork.length; i++) {
          var totalMoneyOnHand = 0;
          var totalMoneyPaid = 0;
          var totalTickets = 0;
          var totalPaidTickets = 0;
          var noBets = 0;
          var winnersMoney = 0;

          for (let t = 0; t < this.dailyWork[i].getTickets.length; t++) {
            var tickets =
              this.dailyWork[i].getTickets[t].choosen_numbers.split(":");
            var others = this.dailyWork[i].getTickets[t].others.split(":");
            var drownNumbers =
              this.dailyWork[i].drawn_numbers != ""
                ? this.dailyWork[i].drawn_numbers.split(",")
                : [];
            var evenOdd = this.dailyWork[i].even_odd;
            var highLow = this.dailyWork[i].high_low;
            if (this.dailyWork[i].getTickets[t].is_cancelled == 0) {
              if (tickets[0] != "") {
                noBets = noBets + tickets.length;
                for (let j = 0; j < tickets.length; j++) {
                  totalMoneyOnHand =
                    totalMoneyOnHand + parseFloat(tickets[j].split("*")[1]);

                  var eachNumber = tickets[j].split("*")[0].split(",");
                  var eachAmount = parseFloat(tickets[j].split("*")[1]);

                  var matchCount = 0;
                  for (let k = 0; k < eachNumber.length; k++) {
                    for (let m = 0; m < drownNumbers.length; m++) {
                      if (drownNumbers[m] == eachNumber[k].trim()) {
                        matchCount++;
                      }
                    }
                  }

                  for (let x = 0; x < rowOut2.length; x++) {
                    if (
                      parseInt(rowOut2[x].choosen_length) == eachNumber.length
                    ) {
                      var hits = rowOut2[x].hits.split(",");
                      var possibleWinMoney = rowOut2[x].odd_value.split(",");

                      for (let y = 0; y < hits.length; y++) {
                        if (matchCount == parseInt(hits[y].trim())) {
                          winnersMoney =
                            winnersMoney +
                            (parseFloat(possibleWinMoney[y].trim()) / 10) *
                              eachAmount;
                        }
                      }
                    }
                  }
                }
              }

              if (others[0] != "") {
                noBets = noBets + others.length;
                for (let r = 0; r < others.length; r++) {
                  totalMoneyOnHand =
                    totalMoneyOnHand + parseFloat(others[r].split("*")[1]);

                  if (
                    evenOdd.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase()
                  ) {
                    for (let x = 0; x < rowOut2.length; x++) {
                      if (
                        rowOut2[x].choosen_length.toLowerCase() ==
                        evenOdd.toLowerCase()
                      ) {
                        winnersMoney =
                          winnersMoney +
                          parseFloat(rowOut2[x].odd_value) *
                            parseFloat(others[r].split("*")[1].trim());
                      }
                    }
                  }

                  if (
                    highLow.toLowerCase() ==
                    others[r].split("*")[0].trim().toLowerCase()
                  ) {
                    for (let x = 0; x < rowOut2.length; x++) {
                      if (
                        rowOut2[x].choosen_length.toLowerCase() ==
                        highLow.toLowerCase()
                      ) {
                        winnersMoney =
                          winnersMoney +
                          parseFloat(rowOut2[x].odd_value) *
                            parseFloat(others[r].split("*")[1].trim());
                      }
                    }
                  }
                }
              }
            }

            totalMoneyPaid =
              totalMoneyPaid + this.dailyWork[i].getTickets[t].winner_award;

            if (this.dailyWork[i].getTickets[t].is_cancelled == 0)
              totalTickets = totalTickets + 1;
            if (this.dailyWork[i].getTickets[t].winner_award != 0)
              totalPaidTickets = totalPaidTickets + 1;
          }

          this.dailyReport.push({
            date: this.dailyWork[i].game_date,
            gameNo: this.dailyWork[i].game_number,
            totalTickets: totalTickets,
            totalPaidTickets: totalPaidTickets,
            cancelledTickets:
              this.dailyWork[i].getTickets.length - totalTickets,
            noBets: noBets,

            totalMoney: totalMoneyOnHand,
            winnerMoney: winnersMoney,
            paidMoney: totalMoneyPaid,
            totalEarning: totalMoneyOnHand - winnersMoney,
          });
        }
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },

    async getOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.partnerURL,

          data: {
            query: `{
                getOdds{
                  odds_id
                  choosen_length
                  hits
                  odd_value

                }

              }
              `,
          },
        });

        this.odds = oddsResult.data.data.getOdds;
      } catch (err) {}
    },

    async getTodaysSpinGameAnalysis() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 8683989;

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var gameAnalysisResult = await axios({
          method: "POST",
          url: this.$store.state.spinPartnerURL,

          data: {
            query: `{
               getTodaysGameAnalysis(date1:"${date1}", date2:"${date2}"){
                   game_id                 
                   game_number
                   drawn_numbers
                   game_date
                   high_low
                   even_odd
                   game_status
                   getSpinNumber{
                      spin_number_id
                      number
                      color
                      mirror
                      twins
                      final
                      dozen
                      sector
                      combination
                      even_odd
                      high_low
                      neighbors
                  }
                   getTickets {
                     ticket_id
                     game_id
                     user_id
                     choosen_numbers
                     others
                     ticket_date
                     winner_award
                     is_cancelled
                   }
                    
              }
              
              }
              `,
          },
        });
        this.spinDailyWork = gameAnalysisResult.data.data.getTodaysGameAnalysis;

        this.spinDailyReport = [];
        var rowOut2 = this.spinOdds;

        for (let i = 0; i < this.spinDailyWork.length; i++) {
          var totalMoneyOnHand = 0;
          var totalMoneyPaid = 0;
          var totalTickets = 0;
          var totalPaidTickets = 0;
          var noBets = 0;
          var winnersMoney = 0;

          for (let t = 0; t < this.spinDailyWork[i].getTickets.length; t++) {
            var tickets =
              this.spinDailyWork[i].getTickets[t].choosen_numbers.split(":");

            var spinNum = this.spinDailyWork[i].getSpinNumber;
            if (this.spinDailyWork[i].getTickets[t].is_cancelled == 0) {
              if (tickets[0] != "") {
                noBets = noBets + tickets.length;
                for (let j = 0; j < tickets.length; j++) {
                  totalMoneyOnHand =
                    totalMoneyOnHand + parseFloat(tickets[j].split("*")[1]);

                  var eachNumber = tickets[j].split("*")[0].trim();
                  var eachAmount = parseFloat(tickets[j].split("*")[1]);
                  if (spinNum.length > 0) {
                    if (spinNum[0].number.toString() == eachNumber) {
                      winnersMoney =
                        winnersMoney + rowOut2[0].number * eachAmount;
                    } else if (eachNumber.includes(",")) {
                      if (
                        eachNumber.split(",")[0].trim() ==
                          spinNum[0].number.toString() ||
                        eachNumber.split(",")[1].trim() ==
                          spinNum[0].number.toString()
                      ) {
                        winnersMoney =
                          winnersMoney + (rowOut2[0].number / 2) * eachAmount;
                      }
                    } else if (spinNum[0].color == eachNumber) {
                      if (eachNumber == "0 Green") {
                        winnersMoney =
                          winnersMoney + rowOut2[0].number * eachAmount;
                      } else {
                        winnersMoney =
                          winnersMoney + rowOut2[0].high_low * eachAmount;
                      }
                    } else if (spinNum[0].sector == eachNumber) {
                      winnersMoney =
                        winnersMoney + rowOut2[0].sector * eachAmount;
                    } else if (spinNum[0].even_odd == eachNumber) {
                      winnersMoney =
                        winnersMoney + rowOut2[0].even_odd * eachAmount;
                    } else if (spinNum[0].high_low == eachNumber) {
                      winnersMoney =
                        winnersMoney + rowOut2[0].high_low * eachAmount;
                    } else if (
                      spinNum[0].neighbors.split(",")[0].trim() == eachNumber ||
                      spinNum[0].neighbors.split(",")[1].trim() == eachNumber ||
                      spinNum[0].neighbors.split(",")[2].trim() == eachNumber ||
                      spinNum[0].neighbors.split(",")[3].trim() == eachNumber ||
                      spinNum[0].neighbors.split(",")[4].trim() == eachNumber
                    ) {
                      winnersMoney =
                        winnersMoney + rowOut2[0].neighbors * eachAmount;
                    } else if (spinNum[0].twins == eachNumber) {
                      winnersMoney =
                        winnersMoney + rowOut2[0].twins * eachAmount;
                    } else if (spinNum[0].dozen == eachNumber) {
                      winnersMoney =
                        winnersMoney + rowOut2[0].dozen * eachAmount;
                    } else if (eachNumber.includes("Final")) {
                      if (spinNum[0].final.includes(eachNumber)) {
                        winnersMoney =
                          winnersMoney + rowOut2[0].final * eachAmount;
                      }
                    } else if (spinNum[0].mirror == eachNumber) {
                      winnersMoney =
                        winnersMoney + rowOut2[0].mirror * eachAmount;
                    } else if (spinNum[0].combination == eachNumber) {
                      winnersMoney =
                        winnersMoney + rowOut2[0].high_low_color * eachAmount;
                    }
                  }
                }
              }
            }

            totalMoneyPaid =
              totalMoneyPaid + this.spinDailyWork[i].getTickets[t].winner_award;

            if (this.spinDailyWork[i].getTickets[t].is_cancelled == 0)
              totalTickets = totalTickets + 1;
            if (this.spinDailyWork[i].getTickets[t].winner_award != 0)
              totalPaidTickets = totalPaidTickets + 1;
          }

          this.spinDailyReport.push({
            date: this.spinDailyWork[i].game_date,
            gameNo: this.spinDailyWork[i].game_number,
            totalTickets: totalTickets,
            totalPaidTickets: totalPaidTickets,
            cancelledTickets:
              this.spinDailyWork[i].getTickets.length - totalTickets,
            noBets: noBets,

            totalMoney: totalMoneyOnHand,
            winnerMoney: winnersMoney,
            paidMoney: totalMoneyPaid,
            totalEarning: totalMoneyOnHand - winnersMoney,
          });
        }
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },

    async spinGetOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.spinPartnerURL,

          data: {
            query: `{
                getOdds{
                 	odds_id
		                number
		                color
		                mirror
		                twins
		                final
		                dozen

		                sector
		                high_low_color
		                even_odd
		                high_low
		                neighbors

                }

              }
              `,
          },
        });

        this.spinOdds = oddsResult.data.data.getOdds;
      } catch (err) {}
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },

  async created() {
    if (this.$store.state.partner != "") {
      await this.getOdds();
      await this.getTodaysGameAnalysis();
      await this.spinGetOdds();
      await this.getTodaysSpinGameAnalysis();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
